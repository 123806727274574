import type { MediaItemType } from 'src/types';
import { SUPPORTED_VIDEO_FORMATS } from 'src/utils/constants';

export const getVideoDimensions = (media: MediaItemType) => {
  return new Promise<{ width: number; height: number } | undefined>((res) => {
    try {
      const fileExtension = media.file?.name?.split('.').pop()?.toLocaleLowerCase() ?? '';
      const isVideo = SUPPORTED_VIDEO_FORMATS.includes(fileExtension);
      if (!isVideo) {
        res(undefined);
        return;
      }
      const videoElement = document.createElement('video');
      document.body.appendChild(videoElement);
      videoElement.style.display = 'none';

      videoElement.src = URL.createObjectURL(media.file!);

      videoElement.addEventListener('loadedmetadata', () => {
        const width = videoElement.videoWidth;
        const height = videoElement.videoHeight;
        URL.revokeObjectURL(videoElement.src);
        videoElement.removeAttribute('src');
        document.body.removeChild(videoElement);
        res({
          width,
          height,
        });
      });
      videoElement.addEventListener('error', () => {
        URL.revokeObjectURL(videoElement.src);
        videoElement.removeAttribute('src');
        document.body.removeChild(videoElement);
        res(undefined);
      });
      videoElement.load();
    } catch (err) {
      res(undefined);
    }
  });
};
