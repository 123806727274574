import type { TFunction } from 'react-i18next';
import type ReactQuill from 'react-quill';
import type { Range } from 'react-quill';

import type { IUser } from './userTypes';

export enum ChatTypesENUM {
  channel = 'channel',
  group = 'group',
  direct = 'direct',
}

export enum MessageTypeENUM {
  message = 'message',
  action = 'action',
}

export enum SearchResultsCategoryENUM {
  members = 'members',
  channels = 'channels',
  messages = 'messages',
}

export type MessageStatusType = 'sending' | 'sended' | 'readed';

export type MediaLocalType = {
  mediaInfoId: string;
  file: File;
  totalSize: number;
  uploaded: number;
  isError: boolean;
  isUploaded: boolean;
  mediaItem?: IMedia;
};

export type MediaInfoType = {
  [mediaInfoId: string]: MediaLocalType;
};

export type ChatMediaObjectType = {
  [channelId: string]: MediaInfoType | null;
};

export interface IMedia {
  mediaItemId: number;
  mimeType: string;
  createdAt: string | Date;
  updatedAt: string | Date;
  deletedAt: string | Date | null;
  name: string;
  isPrivate: boolean;
  key: string;
  thumbnailKey: string;
  tinyThumbnailKey: string;
  userUploaded?: IUser;
  messages?: IMessage[];
  userAvatar?: IUser;
  messageId?: number;
  link?: string;
  thumbLink?: string;
  tinyThumbLink?: string;
  isTemporary?: boolean;
  width?: number;
  height?: number;
}

export type ReactionOldType = {
  reactionEmoji: string;
  users: [
    {
      userId: number;
      firstName: string;
      lastName: string;
      jobPosition: {
        jobPositionId: number;
        name: string;
      };
      fullName: string;
      workingFrom: string | null;
    },
  ];
};

export type ReactionType = {
  shortcode: string;
  reactions: Array<{
    user: {
      userId: number;
      firstName: string;
      lastName: string;
      jobPosition?: {
        jobPositionId: number;
        name: string;
      };
      fullName: string;
      workingFrom?: string | null;
    };
    emoji: string;
    modificator: string | null;
  }>;
};

export enum LinkTypeENUM {
  DEFAULT = 'default',
  IMAGE = 'img',
  SVG = 'svg',
}

export type MessageLinkPreviewType = {
  messageLinkPreviewId: number;
  siteName: string | null;
  title: string | null;
  url: string;
  description: string | null;
  iconLink: string | null;
  imageLink: string | null;
  type: LinkTypeENUM;
};

export interface IMessage {
  messageId: number;
  isAnswer: boolean;
  createdAt: string | Date;
  updatedAt?: string | Date;
  deletedAt?: string | Date;
  isEdited: boolean;
  userEditedAt?: string | Date;
  authorId?: number;
  channel?: IChannel;
  channelId?: number;
  messageReferences?: IMessage[];
  referencedMessageId?: number | null;
  referencedMessage?: IMessage | null;
  childMessages?: IMessage[];
  parentMessageId?: number | null;
  parentMessage?: IMessage | null;
  messageText?: IMessageText[];
  previewMessages?: PreviewMessagesType[];

  /** @deprecated */
  messageToReactions?: IReaction[];
  hasBeenRead: boolean;
  type?: MessageTypeENUM;
  isSending?: boolean;
  isError?: boolean;
  isPinned?: boolean;
  isViewed?: boolean;
  media?: IMedia[];
  parentId?: number | null;

  /** @deprecated */
  messageReactions?: ReactionOldType[];
  reactions?: ReactionType[];
  messageLinkPreviews?: MessageLinkPreviewType[];

  author?: IUser;
}

export type IPreviewMessage = Omit<IMessage, 'messageLinkPreviews' | 'previewMessageIds'> & {
  channel: IChannel;
};

export type PreviewMessagesType = {
  id: number;
  visible: boolean;
};

export interface IMessageText {
  messageTextId: number;
  createdAt: string | Date;
  updatedAt: string | Date;
  text: string;
  deletedAt?: string | Date;
  message?: IMessage;
}

export type UserToChannelsType = {
  userChannelId: number;
  userId: number;
  createdAt?: string | Date;
  lastViewedMessageTime?: string | Date;
  channel?: ChannelType;
  deletedAt?: string | Date;
  isVisible?: boolean;
};

export type UserToThreadType = {
  userThreadId: number;
  createdAt?: string | Date;
  lastViewedMessageTime: string | Date;
  userId: number;
  rootMessageId: number;
  message?: IMessage;
};

export interface IChannel {
  channelId: number;
  createdAt: string | Date;
  updatedAt: string | Date;
  deletedAt: string | Date | null;
  isPrivate: boolean;
  isArchived: boolean;
  type: ChatTypesENUM;
  name: string;
  icon: string;
  ownerId?: number;
  companyId?: number;
  messages?: IMessage[];
  files?: IMedia[];
  userToChannels?: UserToChannelsType[];
  unreadMessagesCount: number;
  mentionsCount: number;
  description: string;
  creatorId?: number;
  pinnedMessagesIds?: number[];

  limitedAccess?: boolean;
  writeAccessUsersIds?: number[];
}

export interface IChannelDelta {
  channelId: number;
  ownerId: number;
  owner?: IUser;
}

export type ChannelType = {
  channelId: number;
  createdAt: string | Date;
  updatedAt: string | Date;
  deletedAt: string | Date | null;
  isPrivate: boolean;
  isArchived: boolean;
  type: ChatTypesENUM;
  name: string;
  icon: string;
  messagesIds?: number[];
  sendingMessagesIds?: number[];
  files?: IMedia[];
  userToChannels?: UserToChannelsType[];
  unreadMessagesCount: number;
  mentionsCount: number;
  description: string;
  pinnedMessages?: IMessage[];
  ownerId?: number;
  creatorId?: number;
  pinnedMessagesIds?: number[];
};

export type MessageType = IMessage & {
  mediaLocal?: MediaLocalType[];
  childMessagesIds?: number[];
  hasDiscussion?: boolean;
};
// export type MessageType = {
//   messageId: number;
//   isAnswer: boolean;
//   createdAt: string | Date;
//   updatedAt?: string | Date;
//   deletedAt?: string | Date;
//   isEdited: boolean;
//   author: IUser;
//   authorId?: number;
//   channel?: IChannel;
//   userEditedAt?: string | Date;
//   messageReferences?: IMessage[];
//   referencedMessageId?: number | null;
//   referencedMessage?: IMessage;
//   childMessagesIds?: number[];
//   parentMessageId?: number | null;
//   parentMessage?: IMessage;
//   type?: MessageTypeENUM;
//   messageText?: IMessageText[];
//   media?: IMedia[];
//   mediaLocal?: MediaLocalType[];
//   messageToReactions?: IReaction[];
//   hasBeenRead: boolean;
//   hasDiscussion?: boolean;
//   isViewed?: boolean;
//   isSending?: boolean;
//   isError?: boolean;
//   isPinned?: boolean;
//   reactions?: IReaction[];
// };

export interface IDiscussion {
  messagesIds?: number[];
  files?: File[];
}

export enum MessageActionModeENUM {
  editing = 'editing',
  replying = 'replying',
  pinned = 'pinned',
  normal = 'normal',
}

export interface IUserChannel {
  direct: IChannel[];
  group: IChannel[];
  channel: IChannel[];
}

export interface IEmoji {
  id: string;
  name: string;
  native: string;
  unified: string;
  keywords: string[];
  shortcodes: string;
  emoticons?: string[];
}

export interface IReaction {
  messageReactionId: number;
  reaction: {
    emoji: string;
  };
  user: IUser;
  createdAt: string | Date;
}

export type DraftDataType = {
  channels: Record<string, string>;
  threads: Record<string, string>;
};

export type UnsentMessagesType = {
  [channelId: number]: Record<number, MessageType>;
};

export type DeleteMediaItemArgsType = {
  messageId: number;
  mediaItemId: number;
  t: TFunction<('chat' | 'errors')[], undefined>;
  mode?: MessageActionModeENUM;
};

export type TypingUsersType = {
  [id: number]: IUser & {
    timestamp: number;
  };
};

export type TooltipButtonType = {
  id: number;
  class: string;
  tooltip: string;
  isDisabledWhenCodeBlockIsActive?: boolean;
  value?: string;
  withSeparator?: boolean;
};

export enum MediaItemMimeTypeENUM {
  pdf = 'pdf',
  video = 'video',
  audio = 'audio',
  archive = 'zip/rar',
  image = 'image',
  word = 'msword/wordprocessingml',
}

export type ThreadMetaDataType = {
  parentMessageId: number;
  unreadMessagesCount: number;
  isContainsMention: boolean;
  isParentUnread: boolean;
};
export type ChannelMetaDataType = {
  channelId: number;
  unreadMessagesCount: number;
  isContainsMention: boolean;
  lastMessageDate?: string;
  threads: {
    [parentMessageId: number]: ThreadMetaDataType;
  };
};

export type MediaItemType = {
  id: string;
  file: File | null;
  src: string;
  progress: number;
  isLoading: boolean;
  mediaItemId?: number;
  fileType: MediaItemMimeTypeENUM;
  totalSize: number;
  errorMessage?: string;
  uploadedData?: number;
  mediaItem?: IMedia;
  fileExtension?: string;
  isSent?: boolean;
};

type BindingsType = {
  key: number;
  shortKey?: boolean;
  handler: (range?: Range, context?: unknown) => void;
};

export type ReactQuillExtendedType = ReactQuill & {
  editor: {
    keyboard: {
      bindings: {
        [key: string]: Array<BindingsType>;
      };
    };
  };
};

export interface IGroupMessages {
  date?: string | undefined;
  key: string;
  messages: IMessage[];
}

export interface IChannelVisiblePayload {
  channel: IChannel;
  meta: ChannelMetaDataType;
}
